



































































































































































// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';

// --- Models ---

// --- Services ---
import AppService from '@/services/app';
import AuthService from '@/services/auth';

// --- Third Party imports ---
import crypto from 'crypto-js';

@Component
export default class SetPasswordPage extends Vue {
  user = {
    username: '',
    password: '',
    confirmPassword: '',
    token: '',
  };

  valid: boolean = false;
  clicked: boolean = false;
  loader: string = '';
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  rules = {
    required: (value: string) => value != null && !!value.toString().trim() || '',
    min: (value: string) => value.length >= 8 || 'At Least 8 Characters',
    passwordMatch: (value: string) => this.user.password === this.user.confirmPassword || 'Passwords Must Match',
  };

  validatePasswordFields() {
    if (this.$refs.setPasswordForm_password) {
      (this.$refs.setPasswordForm_password as any).validate();
    }
    if (this.$refs.setPasswordForm_confirmPassword) {
      (this.$refs.setPasswordForm_confirmPassword as any).validate();
    }
  }

  getCopyRight() {
    return process.env.VUE_APP_COPYRIGHT;
  }

  beforeMount() {
    if (this.$route.params.token === undefined) {
      this.$router.push('/');
    } else {
      this.user.token = this.$route.params.token;
    }
  }

  async setPassword() {
    try {
      this.clicked = true;
      this.loader = 'clicked';

      const response = await AuthService.setPassword({ 
        username: this.user.username,
        password: crypto.SHA256(this.user.password).toString().toUpperCase(),
        token: this.user.token,
      });

      if (response && response.data) {
        if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
        } else {
            this.$router.push('/');
        }
      } else {
        // response is undefined or has no data field - SHOULD NEVER HAPPEN!
        throw new Error('response: ' + JSON.stringify(response));
      }

    } catch (error) {
      
      if (error.response) {
        AppService.errorHandler(error.response.statusText);
      } else {
        AppService.logSupportDebug('Password_Set.vue - setPassword - 245 - ' + error);
        AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
      }

    } finally {
      this.clicked = false;
      this.loader = '';
    }
  }

  goToLogin() {
    this.$router.push({ name: 'Login'});
  }

  goToRegister() {
    this.$router.push({ name: 'Register'});
  }
}
